let app = {
  trace: function (io) {
    console.log(io);
  },

  init: function () {
    let _startScrollPos = window.pageYOffset;

    if (_startScrollPos >= 170) {
      document.querySelector("body").classList.add("sticky");
      document.querySelector(".main-header").classList.add("sticky");
    }

    this.renderBody();
    this.initWindowResize();
    this.initScroll();
    this.initBtns();
    this.initFluidVids();
    this.initEventForm();
    this.initBasicMemberForm();
    this.initPlusMemberForm();
  },

  initBasicMemberForm: function () {
    const formEl = document.getElementById("mc-embedded-subscribe-form");

    if (formEl) {
      formEl.addEventListener("submit", function (e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(e.target);
        const formDataParams = new URLSearchParams(formData).toString();

        let url = form.getAttribute("action");
        url = url.replace("/post?u=", "/post-json?u=");
        url += "&" + formDataParams + "&c=displayMailChimpStatus";

        // Create script with url and callback (if specified)
        const ref = window.document.getElementsByTagName("script")[0];
        let script = window.document.createElement("script");
        script.src = url;
        ref.parentNode.insertBefore(script, ref);

        // mailchimp status element
        window.mcStatus = form.querySelector(".mce-status-response");

        // clean up script after submit
        script.onload = function () {
          this.remove();
        };
      });
    }
  },

  initPlusMemberForm: function () {
    const helpTxtWrapper = document.getElementById("hidden-help-txts-holder");
    const form = document.querySelector(".wpcf7-form");

    // hide all info layers on form blur
    if (form != null) {
      form.addEventListener(
        "blur",
        () => {
          // find all info layers
          let allInfoLayers = Array.from(
            document.querySelectorAll(".info-layer-wrapper")
          );

          // hide all info layers
          allInfoLayers.map((infoLayer) => {
            infoLayer.classList.remove("show-help-txt");
          });
        },
        true
      );
    }

    // copy help texts to real containers
    if (helpTxtWrapper != null) {
      const helpTxt1 = document.getElementById(
        "pitch-form-help-txt-1"
      ).innerText;
      const helpTxt2 = document.getElementById(
        "pitch-form-help-txt-2"
      ).innerText;
      const helpTxt3 = document.getElementById(
        "pitch-form-help-txt-3"
      ).innerText;
      const helpTxt4 = document.getElementById(
        "pitch-form-help-txt-4"
      ).innerText;
      const helpTxt5 = document.getElementById(
        "pitch-form-help-txt-5"
      ).innerText;

      const helpTxtReceiver1 = document.getElementById("help-txt-1");
      const helpTxtReceiver2 = document.getElementById("help-txt-2");
      const helpTxtReceiver3 = document.getElementById("help-txt-3");
      const helpTxtReceiver4 = document.getElementById("help-txt-4");
      const helpTxtReceiver5 = document.getElementById("help-txt-5");

      if (helpTxtReceiver1 != null) {
        helpTxtReceiver1.innerText = helpTxt1;
        helpTxtReceiver2.innerText = helpTxt2;
        helpTxtReceiver3.innerText = helpTxt3;
        helpTxtReceiver4.innerText = helpTxt4;
        helpTxtReceiver5.innerText = helpTxt5;
      }

      const iconInfoBtns = Array.from(document.querySelectorAll(".icon-info"));
      iconInfoBtns.map((btn) => {
        btn.addEventListener("click", function () {
          this.closest(".info-layer-wrapper").classList.toggle("show-help-txt");
        });
      });
    }
  },

  initFluidVids: function () {
    fluidvids.init({
      selector: ["iframe", "object"], // runs querySelectorAll()
      players: ["www.youtube.com", "player.vimeo.com"], // players to support
    });
  },

  initBtns: function () {
    let _openBtns = document.querySelectorAll("[data-open]");

    if (_openBtns !== null) {
      for (let i = 0; i < _openBtns.length; i++) {
        _openBtns[i].addEventListener("click", function () {
          let _target = this.getAttribute("data-open");

          // show overlay
          if (_target !== null && _target === "overlay") {
            document.querySelector("body").classList.add("show-overlay");
          }

          // show mobile nav
          if (_target !== null && _target === "mobile-nav") {
            document.querySelector("body").classList.add("show-mobile-nav");
          }
        });
      }
    }

    let _closeBtns = document.querySelectorAll("[data-close]");

    if (_closeBtns !== null) {
      for (let i = 0; i < _closeBtns.length; i++) {
        _closeBtns[i].addEventListener("click", function () {
          let _target = this.getAttribute("data-close");

          // hide overlay
          if (_target !== null && _target === "overlay") {
            document.querySelector("body").classList.remove("show-overlay");
          }

          // hide mobile nav
          if (_target !== null && _target === "mobile-nav") {
            document.querySelector("body").classList.remove("show-mobile-nav");
          }
        });
      }
    }
  },

  initScroll: function () {
    window.onscroll = function () {
      let _scrollPos = window.pageYOffset;

      if (_scrollPos >= 1) {
        document.querySelector("body").classList.add("sticky");
        document.querySelector(".main-header").classList.add("sticky");
      } else if (_scrollPos < 1) {
        document.querySelector("body").classList.remove("sticky");
        document.querySelector(".main-header").classList.remove("sticky");
      }
    };
  },

  initWindowResize: function () {
    window.addEventListener("resize", function () {
      app.renderBody();
    });
  },

  renderBody: function () {
    let _headerHeight = document.querySelector(".main-header").offsetHeight;
  },

  // event form
  initEventForm: function () {
    document.addEventListener(
      "wpcf7mailsent",
      function (e) {
        let _eventEl = document.querySelector(".event-single-article");
        let _eventOverlay = document.querySelector(".overlay-event-form");
        let _eventID = null;

        if (_eventEl !== null) {
          _eventID = _eventEl.getAttribute("data-id");
        }

        if (_eventOverlay !== null) {
          _eventOverlay.classList.remove("has-form-success");
          _eventOverlay.classList.add("has-form-success");
        }
      },
      false
    );
  },
};

document.onreadystatechange = function () {
  if (document.readyState === "interactive") {
    document.querySelector("html").classList.add("app-interactive");
    app.init();
  } else if (document.readyState === "complete") {
    document.querySelector("html").classList.add("app-complete");
    app.renderBody();
  }
};

window.displayMailChimpStatus = function (data) {
  if (!data.result || !data.msg || !mcStatus) return;

  // set status msg
  mcStatus.innerHTML = data.msg;

  // If error, add error class
  if (data.result === "error") {
    mcStatus.classList.remove("success");
    mcStatus.classList.add("error");
    return;
  }

  // Otherwise, add success class
  mcStatus.classList.remove("error");
  mcStatus.classList.add("success");
};
